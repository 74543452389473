body {
  font-family: "Roboto";
  margin: 0;
  color: #ffffff;
}
.App {
  height: 100vh;
  background: #201a3e;
  h1 {
    color: #ffffff;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1a162c inset !important;
  -webkit-text-fill-color: #7477a0 !important;
}
